export default {
  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.container': {
      backgroundColor: 'transparent',
      padding: ['0.5rem', '0.5rem', '1rem', '1rem'],
      zIndex: '1001'
    },
    '.logo': {
      img: {
        opacity: '1',
        filter: 'brightness(0) invert(1)',
        maxHeight: ['55px', '80px']
      }
    },
    '.logoScrolled': {
      img: {
        filter: 'brightness(0) invert(1)',
        opacity: '1',
        maxHeight: ['45px', '55px']
      }
    },

    '.smallNavMenu': {
      marginRight: '1rem',

      '.navItem': {
        color: 'white',
        a: {
          fontSize: ['1rem', '1rem', '1rem', '1rem'],
          ':hover': {
            color: 'lightgrey'
          }
        }
      }
    },

    '.navItem': {
      color: 'light',
      margin: '0rem',
      padding: ['0.75rem', '0.75rem', '1rem'],
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ':hover': {
        color: 'lightgrey'
      }
    },
    '.hamburger': {
      margin: '0rem',
      backgroundColor: 'secondary',
      padding: '0.5rem',
      border: 'solid 1px white',
      width: '45px',
      height: '45px',
      '> div': {
        backgroundColor: 'white'
      }
    },

    '.navMenuLogo': {
      position: 'static',
      width: ['100%', '', '100%'],
      padding: ['0.5rem', '', '2rem'],
      borderBottom: '1px solid',
      borderColor: 'secondary',
      margin: '0rem',
      backgroundColor: 'black',
      img: {
        filter: 'brightness(0) invert(1)',
        maxHeight: ['55px', '', '100px', '']
      }
    },
    '.navMenuOpen': {
      backgroundColor: 'black',
      borderLeft: '3px solid',
      borderColor: 'primary',

      // width: ['90%', '', '40%'],
      // justifyContent: ['flex-start', 'flex-start', 'flex-start'],
      // padding: '0rem',
      '.seperatorLine': {
        // width: '100%',
        // maxWidth: 'unset',
        // borderColor: 'secondary',
        // margin: '1rem 0rem 0rem',
      },
      '.navItem': {
        // padding: ['0.75rem', '0.75rem', '1rem', '0.75rem', '0.75rem', '1rem'],
        // a: {
        //   fontSize: ['1rem', '1.25rem', '1.5rem', '1.75rem'],
        // },
      }
    },
    '.navBlock': {
      backdropFilter: 'blur(2px)',
      backgroundColor: 'rgb(0 0 0 / 70%)'
    },

    '.socialIconsContainer': {
      margin: '0.5rem',
      svg: {
        path: {
          fill: 'light'
        }
      }
    },
    '.phoneContainer': {
      borderTop: 'solid 2px',
      paddingTop: '1rem',
      margin: '1rem'
    },

    '.containerScrolled': {
      backgroundColor: 'black',
      borderBottom: '2px solid',
      borderColor: 'secondary',
      zIndex: '1001',
      '.hamburger > div': {
        // backgroundColor: '#c6a34d',
      }
    }
  },

  footer: {
    maxWidth: 'unset',
    backgroundColor: 'black',
    '.logo': {
      filter: 'brightness(0) invert(1)',
      // filter: 'unset',
      maxHeight: '90px'
    },
    '.socialContainer': {
      margin: '0.5rem'
    }
  },

  ctaWidget: {
    display: 'none',
    a: {
      backgroundColor: 'secondary'
    }
  },

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    width: 'fit-content',
    // borderBottom: '2px solid',
    borderColor: 'secondary',
    display: 'flex',
    flexDirection: 'column',
    fontSize: ['1.75rem', '', '2.5rem'],
    textTransform: 'uppercase',
    color: 'text',
    fontWeight: 'bold',
    letterSpacing: '2px',
    whiteSpace: 'pre-wrap',
    marginBottom: '1.5rem'
  },

  subtitle: {
    color: 'white',
    fontWeight: 'normal',
    textTransform: 'initial',
    order: '1',
    fontFamily: 'body',
    fontSize: ['1rem', '1.25rem', '1.5rem', '1.75rem'],
    textTransform: 'uppercase'
  },

  text: {
    order: '3'
  },

  sideBySide1: {
    overflow: 'hidden',
    padding: ['0rem', '1rem', '', '2rem'],
    '.content': {
      width: ['', '', '45%'],
      padding: ['2rem 1rem', '', '', '1.5rem 3.5rem', '2.5rem 4.5rem'],
      '.title': {
        variant: 'customVariants.title'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle'
      },
      '.text': {
        variant: 'customVariants.text'
      },
      a: {
        order: '4'
      }
    },
    '.lazyload-wrapper': {
      width: ['', '', '55%'],
      height: ['', '', '55vw'],

      img: {
        filter: 'brightness(1.4)'
      },
      position: 'relative',
      '::before': {
        zIndex: '100',
        content: "''",
        width: '100%',
        height: '100%',
        background: 'radial-gradient(circle, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 65%,rgba(72, 2, 2, 1) 70%)',
        position: 'absolute',
        left: '0rem',
        top: '0rem'
      }
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },

  sideBySide2: {
    variant: 'customVariants.sideBySide1',
    backgroundColor: 'black',
    '.lazyload-wrapper': {
      width: ['', '', '55%'],
      height: ['', '', '55vw'],
      img: {
        filter: 'brightness(1.2)'
      },
      position: 'relative',
      '::before': {
        zIndex: '100',
        content: "''",
        width: '100%',
        height: '100%',
        background: 'radial-gradient(circle, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 65%,rgba(0, 0, 0, 1) 70%)',
        position: 'absolute',
        left: '0rem',
        top: '0rem'
      }
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    '.section': {
      margin: '0rem'
    },
    '.title': {
      variant: 'customVariants.title',
      border: 'none',
      padding: '0rem',
      fontSize: ['2rem', '2.5rem', '3.5rem', '5.5rem'],
      textShadow: '2px 2px 10px black',
      color: 'light'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      paddingBottom: '1rem',
      marginBottom: '1rem'
    },
    '.text': {
      color: 'white'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },
  homepageHeroShout: {
    display: 'none'
  },

  homepageShout: {
    '.title': {
      variant: 'customVariants.title',
      marginBottom: '0rem',
      textAlign: 'left',
      alignItems: 'flex-start',
      justifyContent: 'center'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text',
      backgroundColor: '#260000'
    },
    '.date': {}
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1'
  },

  homepageMenu: {
    variant: 'customVariants.sideBySide2'
  },

  homepageFood: {
    '.title': {
      padding: '2rem',
      border: 'solid 2px white',
      width: ['fit-content'],
      fontSize: ['1.75rem', '1.75rem', '2.5rem', '3rem', '4rem']
    },
    img: {
      filter: 'brightness(1.2)'
    }
  },

  homepageGallery: {
    variant: 'customVariants.sideBySide2'
  },

  homepageDrinks: {
    img: {
      filter: 'brightness(1.2)'
    },
    '.title': {
      padding: '2rem',
      border: 'solid 2px white',
      fontSize: ['1.75rem', '1.75rem', '2.5rem', '3rem', '4rem'],
      width: ['fit-content']
    }
  },

  homepageEvents: {
    variant: 'customVariants.sideBySide2'
  },

  homepageLocation: {
    variant: 'customVariants.sideBySide1'
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    padding: ['3rem 1rem', '', '6rem 1rem', '7rem 1rem'],
    '.title': {
      fontSize: '2.5rem',
      color: 'secondary',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'secondary'
    }
  },

  // ? ========================
  // ? =====  Menu page  =====
  // ? ========================

  menu: {
    margin: '0rem 0rem',
    maxWidth: 'unset',
    padding: ['2rem 1rem', '', '3rem 2rem'],
    // backgroundImage:
    //   'url(https://res.cloudinary.com/gonation/image/upload/sites/cast-iron-chef-kitchen-bar/pattern-bg.png)',
    // backgroundColor: '#fff8f8',
    backgroundSize: 'auto',
    backgroundRepeat: 'repeat',
    '.backToMenuBtn': {
      variant: 'buttons.secondary',
      margin: '0rem auto 2rem 0rem'
    },
    '.dropdownContainer': {
      display: 'none'
    },
    '.section-cell': {
      width: ['100%', '', 'calc(50% - 1rem)'],
      borderRadius: '0px',
      position: 'relative'
    },
    '.allInContainerWrapper ': {
      maxWidth: '1200px',
      margin: '1rem auto',
      borderTop: 'dashed 3px white',
      padding: ['1rem 0rem']
    },
    '.cellImageContainer': {
      overflow: 'hidden'
    },
    '.cellImage': {
      borderRadius: '0px',
      transition: 'all ease-in-out 0.5s',
      ':hover': {
        transform: 'scale(1.1) translate(-50%, -50%)',
        transformOrigin: 'center center'
      }
    },
    '.menuCell': {
      borderRadius: '0px',
      '.imageFill': {
        paddingBottom: '60%'
      }
    },

    '.cellName': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: 'light',
      margin: '0rem',
      fontSize: ['1.5rem', '', '1.75rem', '2.5rem']
    },

    '.tabsRow': {
      justifyContent: ['flex-start', '', '', 'flex-end'],
      alignItems: 'center',
      maxWidth: '1200px',
      margin: '1rem auto',
      '::before': {
        variant: 'customVariants.title',
        fontSize: ['1.5rem', '1.75rem', '2.5rem', '2.5rem', '3rem'],
        content: "'Explore The Menu'",
        margin: ['0rem 0rem 1.5rem', '', '0rem auto 0rem 0rem'],
        width: ['100%', '', '', 'fit-content'],
        letterSpacing: '0px',
        textTransform: 'capitalize',
        padding: '1rem 0rem',
        borderRight: ['', '', '', '2px white solid'],
        paddingRight: ['', '', '', '2rem'],
        marginRight: '2rem'
      },
      '.menuTab': {
        fontSize: '0.9rem',
        border: 'none',
        padding: '0.25rem',
        letterSpacing: '-1px',
        fontFamily: 'heading',
        height: 'fit-content',
        fontWeight: '300',
        backgroundColor: 'transparent',
        margin: '1rem 0.25rem 0rem',
        fontWeight: 'bold',
        textTransform: 'uppercase'
      },
      '.menuTabActive': {
        color: 'text',
        borderBottom: '1px solid black'
      }
    },

    '.menuContainer': {
      paddingBottom: '0rem'
    },

    '.menuSectionTitle': {
      textAlign: 'left',
      margin: '1rem 0rem 1.5rem',
      fontSize: ['1.25rem', '1.4rem', '1.5rem', '1.6rem'],
      width: 'fit-content',
      color: 'white',
      borderBottom: '5px',
      borderColor: 'primary'
    },
    '.menuSectionDescription': {
      textAlign: 'left',
      // opacity: '0.75',
      fontSize: '0.9rem',
      color: '#c3a0a0',
      marginBottom: '2rem'
    },

    '.menuItemContainer, .menuItemContainerImgActive': {
      margin: '0rem 0rem 1.5rem',
      padding: ['0rem', '', '0rem 1.5rem 0rem 0rem']
    },

    '.menuItemInnerContainer': {
      margin: '0rem'
    },
    '.menuItemContentContainer': {
      margin: '0rem'
    },

    '.menuItemName': {
      marginBottom: '0rem',
      fontFamily: 'heading',
      fontSize: ['1rem', '1rem', '1.1rem', '1.2rem']
    },
    '.menuItemDescription': {
      color: '#c3a0a0'
    }
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumsContainer': {
      // display: 'none',
    },
    '.albumTitle': {
      variant: 'buttons.secondary'
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.no-events-container': {
      img: {
        filter: 'brightness(1) invert(1)'
      }
    },
    '.eventItemImage': {
      minHeight: 'unset',
      maxHeight: 'unset',
      height: '100%',
      maxHeight: '450px'
    },
    '.eventDays, .eventDate': {
      color: 'white'
    },
    '.eventDaysContainer span': {
      color: 'white'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    padding: '5rem 1rem',
    order: '4',
    border: ['solid 10px', 'solid 10px ', 'solid 15px'],
    borderColor: ['secondary', 'secondary', 'secondary'],
    backgroundColor: 'black',
    '.title': {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '2.5rem',
      color: 'secondary',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'primary',
      marginBottom: '2rem',
      variant: 'customVariants.title'
    },
    '.subtitle': {
      textTransform: 'initial',
      fontSize: '1rem',
      opacity: '0.8',
      marginBottom: '4rem'
    },
    form: {
      input: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid',
        borderColor: 'primary',
        backgroundColor: 'rgba(0,0,0,0.5)',
        color: 'white'
      },

      textarea: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid',
        borderColor: 'primary',
        backgroundColor: 'rgba(0,0,0,0.5)',
        color: 'white'
      },
      '.submitBtn': {
        variant: 'buttons.secondary',
        // borderRadius: '0px',
        textAlign: 'center',
        justifyContent: 'center'
      }
    }
  },

  locationMap: {
    order: '2',
    backgroundColor: 'black',
    color: 'light',
    display: 'flex',
    flexDirection: ['column', 'column', 'column', 'column'],
    '.content_container': {
      width: '100%',
      border: 'solid 10px',
      borderColor: 'secondary'
    },
    iframe: {
      width: '100%'
    },
    h3: {
      textTransform: 'uppercase',
      fontSize: '2rem',
      color: 'white',
      borderBottom: '2px solid',
      borderColor: 'secondary'
    },
    '.dayofWeekText': {
      margin: '0 auto 0 0'
    }
  },

  // ? ========================
  // ? ====  Events Menu  ====
  // ? ========================

  eventsLeft: {
    variant: 'customVariants.sideBySide1'
  },
  eventsRight: {
    variant: 'customVariants.sideBySide2'
  },

  // ? ========================
  // ? ====  Press Menu  ====
  // ? ========================

  pressMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    backgroundColor: 'background',
    '.boxesHeading': {
      display: 'none'
    },
    '.box': {
      backgroundColor: 'secondary',
      margin: ['1rem', '', '2rem'],
      width: ['100%', 'calc(50% - 0.5rem * 2)', 'calc(33.3% - 2rem * 2)']
    },
    '.imageContainer': {
      backgroundColor: 'black',
      img: {
        objectFit: 'contain'
      }
    },
    '.title': {
      color: 'light',
      borderBottom: '2px solid #cababa'
    },
    '.subtitle': {
      color: 'light'
    }
  },

  pressPage: {},

  // ? ========================
  // ? ====  Team Menu  ====
  // ? ========================

  teamMenu: {},

  teamPage: {},

  // ? ========================
  // ? ====  blog Menu  ====
  // ? ========================

  blogMenu: {},

  blogPage: {}
}
